var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.getHeaders,"items":_vm.getProducts,"item-key":"name","hide-default-footer":true,"options":_vm.getOptions},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex"},'div',attrs,false),on),[_c('img',{attrs:{"src":item.images[0] ? _vm.imageHelpers.url(item.images[0].src) : '@/assets/images/no-image.png',"srcset":"","width":"40"}}),_c('div',{staticClass:"d-flex word-break-all ml-2"},[(!item.deleted && item.store == _vm.getStoreId)?_c('div',[_c('router-link',{staticClass:"invent",attrs:{"to":{ name: 'website.products.update', params: { id: item._id } }}},[_c('span',{staticClass:"text-ref-title"},[_vm._v(_vm._s(item.title))])])],1):(!item.deleted)?_c('div',[_c('span',{staticClass:"text-ref-title"},[_vm._v(_vm._s(item.title))])]):_c('div',[_c('span',{staticClass:"text-ref-title red--text"},[_vm._v(_vm._s(item.title))])])]),(!item.deleted)?_c('v-icon',{staticClass:"icon-append-no-rotate",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.gotoProduct(item)}}},[_vm._v("mdi-open-in-new")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1)]}},{key:"item.view_content",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('div',[_vm._v(_vm._s(item.view_content))])]}},{key:"item.add_to_cart",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('span',[_vm._v(_vm._s(item.add_to_cart))])]}},{key:"item.reached_checkout",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('span',[_vm._v(_vm._s(item.reached_checkout))])]}},{key:"item.session_converted",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('span',[_vm._v(_vm._s(item.session_converted))])]}},{key:"item.orders",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('span',[_vm._v(_vm._s(item.orders))])]}},{key:"item.net_qty",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('span',[_vm._v(_vm._s(item.net_qty))])]}},{key:"item.aov",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('span',[_vm._v(_vm._s(_vm.currency.priceFormat(item.aov)))])]}},{key:"item.aoi",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('span',[_vm._v(_vm._s(item.aoi))])]}},{key:"item.total_sale",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('span',[_vm._v(_vm._s(_vm.currency.priceFormat(item.total_sale)))])]}}],null,true)},[_c('template',{slot:"body.prepend"},[_c('tr',{staticClass:"border"},[_c('th',[_vm._v("Summary")]),_c('th',[_vm._v(_vm._s(_vm.sumField('view_content')))]),_c('th',[_vm._v(_vm._s(_vm.sumField('add_to_cart')))]),_c('th',[_vm._v(_vm._s(_vm.sumField('reached_checkout')))]),_c('th',[_vm._v(_vm._s(_vm.sumField('session_converted')))]),_c('th',[_vm._v(_vm._s(_vm.sumField('orders')))]),_c('th',[_vm._v(_vm._s(_vm.sumField('net_qty')))]),_c('th',[_vm._v(_vm._s(_vm.currency.priceFormat(_vm.avgField('aov'))))]),_c('th',[_vm._v(_vm._s(_vm.avgField('aoi')))]),_c('th',[_vm._v(_vm._s(_vm.currency.priceFormat(_vm.sumField('total_sale'))))])])])],2),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.getOptions.totalPage) || 1,"total-visible":0},on:{"input":_vm.onPageChange},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }